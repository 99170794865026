// Here you can add other styles
@import './variables';

.header.header-sticky {
  z-index: 999;
}

.sidebar-nav {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(21, 21, 36, 0.39);
  }
}

.app-error-stack {
  .btn-dismiss {
    float: right;
    font-size: 2rem;
    top: -0.3rem;
    position: relative;
    cursor: pointer;
  }
}

.form-container {
  margin-bottom: 1em;
  .input-description {
    .required {
      font-weight: bold;
      color: $red;
    }
  }
  .input-group {
    .badge {
      display: flex;
      align-items: center;
    }
    .date-picker {
      flex: 1;
      input {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
    .time-picker {
      flex-grow: 1;
      input {
        border-radius: 0;
      }
    }
    .form-multi-select {
      flex: 1;
      padding-right: 4.4rem;
      &.formValidated {
        &.valid {
          border-color: #2eb85c;
          background-image: 'url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%232eb85c%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e)';
        }
        &.invalid {
          border-color: #e55353;
          background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23e55353%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23e55353%27 stroke=%27none%27/%3e%3c/svg%3e');
        }
        &.valid,
        &.invalid {
          background-repeat: no-repeat;
          background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .form-multi-select-search:placeholder-shown {
        width: 94%;
      }
    }
    .toggle-button {
      background: transparent;
      border: 0;
      bottom: 0;
      padding: 0 12px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .was-validated .form-check-input:valid ~ .form-check-label,
  .form-check-input.is-valid ~ .form-check-label {
    color: inherit;
  }
  .rbt.has-aux {
    .rbt-aux {
      right: 23px;
      top: 4px;
      .rbt-close-content {
        display: none;
      }
    }
  }
  // Fix Typehead
  #autoCompleteTechServClient {
    transform: translate(0px, 38px) !important;
  }

  .paid-service-badge .badge {
    margin-bottom: 0.5rem;
    @media only screen and (min-width: map-get($grid-breakpoints, 'lg')) {
      position: absolute !important;
      top: -4px;
      right: 0;
    }
  }
}

.report-table {
  .row {
    margin-left: 0;
  }
}

.tab-content {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border-left: $border-width solid $gray-300;
  border-bottom: $border-width solid $gray-300;
  border-right: $border-width solid $gray-300;
  margin-bottom: 2em;
}

ul.pagination {
  li {
    cursor: pointer;
  }
}

.accordion {
  .accordion-item {
    .accordion-header {
      .accordion-button {
        background-color: whitesmoke;
        transition: background-color 0.5s ease-in-out;
        &:hover {
          background-color: rgb(221, 221, 221);
        }
      }
    }
  }
}

/** Fixes **/

.alert {
  --cui-alert-bg: transparent;
  --cui-alert-padding-x: 1rem;
  --cui-alert-padding-y: 1rem;
  --cui-alert-margin-bottom: 1rem;
  --cui-alert-color: inherit;
  --cui-alert-border-color: transparent;
  --cui-alert-border: 1px solid var(--cui-alert-border-color);
  --cui-alert-border-radius: 0.375rem;
  background-color: var(--cui-alert-bg);
  border: var(--cui-alert-border);
  border-radius: var(--cui-alert-border-radius);
  color: var(--cui-alert-color);
  margin-bottom: var(--cui-alert-margin-bottom);
  padding: var(--cui-alert-padding-y) var(--cui-alert-padding-x);
  position: relative;
  &.alert-danger {
    --cui-alert-color: #893232;
    --cui-alert-bg: #fadddd;
    --cui-alert-border-color: #f7cbcb;
  }
  &.alert-light-danger {
    --cui-alert-color: #893232;
    --cui-alert-bg: #f5eded;
    --cui-alert-border-color: #f7cbcb;
  }
  &.alert-warning {
    --cui-alert-color: rgba(131, 107, 52, 0.97);
    --cui-alert-bg: #feefd0;
    --cui-alert-border-color: #fde8b9;
  }
  &.alert-secondary {
    --cui-alert-color: #41464b;
    --cui-alert-bg: #e2e3e5;
    --cui-alert-border-color: #d3d6d8;
  }
  &.alert-success {
    --cui-alert-color: #0f5132;
    --cui-alert-bg: #d1e7dd;
    --cui-alert-border-color: #badbcc;
  }
}
// popver
.fc .fc-popover {
  z-index: 1000 !important;
}

.ReactModalPortal {
  position: relative;
  z-index: 9999;
}

@import './service_form';
