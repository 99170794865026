.custom-toaster {
  .toast {
    background-color: white;
    width: 500px;
    .toast-header {
      background-color: rgb(240, 240, 240);
      .icon {
        margin-right: 0.5rem;
      }
    }
  }
}
