.react-photo-gallery--gallery {
  > div {
    gap: 1rem;
    .card {
      // flex-basis: 33%;
      // position: relative;
      // overflow: hidden;
      img {
        //  position: absolute;
        //  left: -30%;
      }
    }
  }
}
