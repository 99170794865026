.table-wrapper-inner {
  margin-bottom: 4rem;
  box-shadow: rgb(187 187 187) 0px -6px 7px 0px inset;
  .collapse-table {
    overflow: auto;
    transition: all 0.5s ease-in-out;
    &.collapse-table-off {
      max-height: none;
    }
    &.collapse-table-on {
      max-height: '80rem';
    }
  }
  .spinner-grow {
    width: 2.5rem;
    height: 2.5rem;
  }
  @keyframes blinker {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
}
